


















































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import pagination from '@/components/common/pagination/index.vue'
import pageTitle from '@/components/common/pageTitle.vue'
import AccessImage from '@/components/dashboard/AccessImage/index.vue'
import { areaPath } from '@/utils/getAreaPath'
import SecurityText from '@/components/common/SecurityText/index.vue'
import CopyText from '@/components/common/CopyText.vue'
import { tableScrollTop } from '@/utils/dom'
import _ from 'lodash'

@Component({
  name: 'index',
  components: {
    pagination,
    pageTitle,
    AccessImage,
    SecurityText,
    CopyText
  }
})
export default class AbnormalTemperaturePage extends Vue {
  @Ref('paginationNode') readonly paginationNode: pagination

  private query = {
    limit: 20,
    offset: 0
  }
  private showFlag = false
  private personList: any = []
  private loading = false
  private noDataImg = require('@/assets/images/dashboard/noData.png')
  private filterData: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')]
  }

  private personImg: any[] = [
    { name: '注册图片', url: null, EqualWH: true, BigPicture: false },
    { name: '通行图片', url: null, EqualWH: false, Typesetting: 'vertical', BigPicture: true }
  ]
  private showExtendInfo = false
  private extendInfo: AnyObj = {}

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  mounted() {
    this.getList(this.query.limit, 0)
  }
  deactivated() {
    this.showFlag = false
  }

  private pageChange(data: AnyObj) {
    this.query.limit = data.pageSize
    this.query.offset = (data.pageNo - 1) * data.pageSize
    this.getList(this.query.limit, this.query.offset)
  }
  async getList(pageSize = 20, offset = 0) {
    this.$message.closeAll()
    this.loading = true
    try {
      const resp = await this.getDataList(pageSize, offset)
      if (resp.data && resp.data.data) {
        const { data, count } = resp.data
        this.personList = data.map((item: AnyObj) => {
          Object.assign(item, areaPath(item.areas))
          return item
        })
        this.paginationNode.init({ total: count ? count : 0 })
        tableScrollTop()
      }
    } finally {
      this.loading = false
    }
  }
  private async getDataList(pageSize = 20, offset = 0) {
    const val = this.filterData
    const [startTime, endTime] = val.dateRange

    const par: AnyObj = {
      startTime,
      endTime,
      limit: pageSize,
      offset: offset,
      areaIds: val.areaIds && val.areaIds.length > 0 ? val.areaIds : [this.root],
      passed: false,
      reasons: [5]
    }
    if (val.name && !val.name.match(/^[ ]*$/)) {
      Object.assign(par, { name: val.name })
    }

    return this.$api.europa.getPassagesSearch(par)
  }

  private handleFilter() {
    this.getList(this.query.limit, 0)
    this.paginationNode.initPage({ current: 1 })
  }

  seeExtendInfo(obj: AnyObj) {
    this.showExtendInfo = true
    this.extendInfo = _.cloneDeep(obj)
  }

  private seePassImg(obj: AnyObj) {
    this.showFlag = true
    this.personImg[0].url = obj.targetImageUrl
    this.personImg[1].url = obj.sceneImageUrl
  }
}
